import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  useListContext,
  DateField,
} from "react-admin";
import { Typography } from "@mui/material";
import { Card, Container } from "@mui/material";
import CardContent from "@mui/material/CardContent";

/**
 * Fetch a book from the API and display it
 */


const Aside = () => (
  <div style={{ width: 200, margin: '4em 1em' }}>
      <Typography variant="h6">Odrzucone transakcje</Typography>
      <Typography variant="body2">
        Tutaj znajduje się lista transakcji odrzuconych z powodu braku ilości
        podczas składania zamówienia.
      </Typography>
  </div>
);
const PostPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;
export const DismissList = () => {
  const { data, isLoading } = useListContext();
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoading) return <div>Loading</div>;
  return (
    <>
      <List
        aside={<Aside />}
        sort={{ field: "created_at", order: "DESC" }}
        pagination={<PostPagination />}
      >
        <Datagrid>
          <TextField source="session_id" />
          <TextField source="reason" />
          <TextField source="source" />
          <TextField source="products" />
          <DateField source="created_at" showTime />
        </Datagrid>
      </List>
    </>
  );
};
