import { addRefreshAuthToAuthProvider } from 'react-admin';
import refreshAuth from './refreshAuth.js'
const myAuthProvider = {
    
    // called when the user attempts to log in
    login: async ({ username, password }) => {

        const request = new Request(process.env.REACT_APP_SERVER_URL + '/token', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return await fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, refresh_token, name, surname, username }) => {
                const user= (name!="" || surname!="") ? name+" "+surname: username;
                localStorage.setItem('username', user);
                localStorage.setItem('token', token);
                localStorage.setItem('refresh_token', refresh_token);
            });
    },
    // called when the user clicks on the logout button
    logout: async () => {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        if (username!=null && username!="" && token!="") {
            const request = new Request(process.env.REACT_APP_SERVER_URL + '/logout', {
                method: 'POST',
                body: JSON.stringify({ username }),
                headers: new Headers({ 'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}` }),
            })
            return await fetch(request)
                .then(response => {
                    // if (response.status < 200 || response.status >= 300) {
                    //     throw new Error(response.statusText);
                    // }
                    // return response.json();
                })
                .then((resp) => {
                        localStorage.removeItem('username');
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');
                
                });
        } else {
            return Promise.resolve();
        }
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            // console.log('--refreshtoken');
            // return refreshAuth();
            return Promise.reject(status);
        }
        return Promise.resolve(status);
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: ({status}) => {
        // if (status === 401) {
        //     console.log('--refreshtoken');
        //     return refreshAuth();
        // }
        console.log('check auth');
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    getIdentity: () => {
        Promise.resolve({
            id: 'user',
            fullName: localStorage.getItem('username'),
        })
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
}; 

export const authProvider =  addRefreshAuthToAuthProvider(myAuthProvider, refreshAuth );
//export default myAuthProvider;