export const quantityRanges = [
  {
    name: "Brak stanu",
    translate: "resources.getProducts.filters.no_stock",
    from: -100,
    to: 0,
    qa: undefined,
    color: "red",
    alarm: false,
  },
  {
    name: "Niski stan",
    translate: "resources.getProducts.filters.low_stock",
    from: 1,
    to: 5,
    qa: undefined,
    color: "red",
    alarm: false,
  },
  {
    name: "Średni stan",
    translate: "resources.getProducts.filters.average_stock",
    from: 6,
    to: 30,
    qa: undefined,
    color: "orange",
    alarm: false,
  },
  {
    name: "Wystarczający stan",
    translate: "resources.getProducts.filters.enough_stock",
    from: 31,
    to: undefined,
    qa: undefined,
    color: "inherit",
    alarm: false,
  },
];

export const quantityProvider = {
  getQuantityColor: (quantity) => {
    const range = quantityRanges.find((range) => {
      if (range.to === undefined) {
        return quantity >= range.from;
      }
      return quantity >= range.from && quantity <= range.to;
    });
    return range.color;
  },
};
