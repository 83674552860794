import { List, Datagrid, TextField, TextDateField} from 'react-admin';
import AvailableField from '../components/AvailableField';
import Pagination from '../common/pagination';
import { Typography } from '@mui/material';

const Aside = () => (
    <div style={{ width: 200, margin: '4em 1em' }}>
        <Typography variant="h6">Lista użytowników</Typography>
        <Typography variant="body2">
            Zdefiniowani tutaj użytkownicy mogą logować się do panelu.
        </Typography>
    </div>
);

export const UserList = props => (
    <List {...props} pagination={<Pagination />}  aside={<Aside />}>
        <Datagrid rowClick="edit">
            <TextField source="username" />
            <TextField source="name" />
            <TextField source="surname" />
            <TextField source="email" />
            <TextField source="last_login" />
            <AvailableField source="active" />
        </Datagrid>
    </List>
);