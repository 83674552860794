
import {
 Button, useRecordContext, useRefresh, useNotify, useUpdate
} from 'react-admin';

const ButtonBlock = ( { source }) => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const [update, { isLoading, error }] = useUpdate()
    const block = parseInt(record['block_change']);
    const id = parseInt(record[source]);
    const label = (block===1) ? "Odblokuj" : "Blokuj"; 
    const action = (block===1) ? "unblock_quantity" : "block_quantity"; 
    const variant = (block===1) ? "contained" : "outlined";
    // const handleButtonBlockClick = async (e) => {
    //     e.stopPropagation(); 
    //     const token = localStorage.getItem('token');
    //     await fetch(process.env.REACT_APP_SERVER_URL + '/command/'+action, {
    //         method: "PATCH",
    //         headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    //         body: JSON.stringify({ id:id })
    //     })  
    //     .then((response) => response.json())
    //     .then((data) => {
    //         console.log(data);
        
    //         if (typeof data.error !== 'undefined' && data.error) {
    //             notify(data.reason, { type: 'error' });
    //         } else {
    //             notify("Blokada została zmieniona.", { type: 'success' });
    //             refresh();  
    //         }
    //     })
    //     .catch((error) => {
    //         //notify(`This is an error`, { type: 'error' });
    //         console.log(error)
    //     });
           
    // }
    const handleButtonBlockClick =  (e) => {
        e.stopPropagation(); 
        update('getProducts', { id:id, data: { product_id: id, block_change: (block==0) ? 1:0 }, record}, {
            onSuccess: ({ data }) => {
                notify("Blokada została zmieniona.", { type: 'success' });
                //refresh();
            }
        })
  
    }
    return (
        <Button  key={id}
        variant={variant} 
        onClick={handleButtonBlockClick} 
        label={label}>    
        </Button>
    )
}

export default ButtonBlock;