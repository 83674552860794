import { Layout, Sidebar } from 'react-admin';
import AppBar from './AppBar';
// import { NovaMenu } from './Menu';
const MySidebar = (props) => (
    <Sidebar
        sx={{
            "& .RaSidebar-drawerPaper": {
                backgroundColor: "red",
            },
        }}
        {...props}
    />
);

export const NovaLayout = (props) => <Layout {...props} appBar={AppBar}  sidebar={MySidebar}/>;