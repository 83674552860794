import { useRecordContext } from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const AvailableField = ({ source }) => {
    const record = useRecordContext();
    const available = (record && record[source]) ? record[source] : 0;

    return (
        <span>
            {available ? <CheckIcon /> : <ClearIcon />}
        </span>
    )
}
export default AvailableField;