import * as React from 'react';
import {
    ArrayField,
    SingleFieldList,
    ChipField,
    useRecordContext,
} from 'react-admin';

const ColoredChipField = (props) => {
    const record = useRecordContext();
    let bgcolor;

    switch (record.key) {
        case 'INVENTORY':
            bgcolor = "#7dbde8"
            break;
        case 'RESERVATION':
            bgcolor = "#c5dedd"
            break;
        case 'CANCEL_RESERVATION':
                bgcolor = "#fad2e1"
                break;
        default:
             bgcolor = 'lightblue'
            break;
    }
    return (
        <ChipField
            {...props}
            source="name"
            style={{ backgroundColor: bgcolor, border:"0" }}
        />
    );
};

export const OperationTags = (props) => (
    <ArrayField {...props}
    >
        <SingleFieldList linkType={false} component="span">
            <ColoredChipField source="key" variant="outlined" size="small" {...props} />
        </SingleFieldList>
    </ArrayField>
);