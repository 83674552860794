// in src/posts.js
import * as React from "react";
import { BooleanInput, ListButton, Edit, TopToolbar, SimpleForm, TextInput, required, TextField, Labeled } from 'react-admin';

const PostEditActions = () => (
    <TopToolbar>
           <ListButton label="Wróć"/>
    </TopToolbar>
);

export const UserEdit = () => (


    <Edit actions={<PostEditActions/>}>
       
        <SimpleForm>
            <Labeled source="id">
                <TextField source="id" />
            </Labeled>

            <TextInput source="username" variant="outlined"  size="small" fullWidth validate={required()} />
            <TextInput source="name" variant="outlined"  size="small" fullWidth/>
            <TextInput source="surname" variant="outlined"  size="small" fullWidth/>
            <TextInput source="email" variant="outlined"  size="small" fullWidth/>
            <BooleanInput label="Aktywny" source="active" />

            <TextInput source="password" variant="outlined"  label="Nowe hasło" size="small" fullWidth/>

        </SimpleForm>
    </Edit>

);