import { List, Datagrid, TextField, UrlField} from 'react-admin';
import { Typography } from '@mui/material';
import  { ButtonFeedAction }  from '../components/ButtonFeedAction';
import Pagination from '../common/pagination';
const Aside = () => (
    <div style={{ width: 200, margin: '4em 1em' }}>
        <Typography variant="h6">Zewnętrzne sklepy</Typography>
        <Typography variant="body2">
            Sklepy zewnętrzne to sklepy, które są zintegrowane z systemem MagShare.
        </Typography>
    </div>
);


export const AppList = props => (
    <List {...props} pagination={<Pagination />} aside={<Aside/>}> 
        <Datagrid rowClick="edit">
            <TextField source="shop_name" />
            <TextField source="client_id" />
            <TextField source="feed" />
            <UrlField source="shop_url" />
            {/* <ButtonFeedAction source="feed"></ButtonFeedAction> */}
        </Datagrid>
    </List>
);