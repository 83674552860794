import * as React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import { useRecordContext } from 'react-admin';

const LinkToHistory = ({ product_id }) => {
    const record = useRecordContext();
    let product = record.product_id;
    
    const handleClick = (e) => {
        e.stopPropagation();
    }

    return (
        <Button
            size="small"
            color="primary"
            component={Link}
            onClick={handleClick}
            to={{
                pathname: '/getHistory',
                search: stringify({
                    filter: JSON.stringify({ product_id: product }),
                }),
            }}
            state={{ _scrollToTop: true }}
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
            }}
        >
          Historia
        </Button>
    );
};

export default LinkToHistory;