
import {
 Button, useRecordContext, useRefresh, useNotify
} from 'react-admin';

export const ButtonFeedAction = ( ) => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();

    const client_id = record['client_id'];
    const label = "Pobierz produkty";
    const action =  "download_feed"
    const varriant = "contained";
    const handleButtonBlockClick = async () => {
        const token = localStorage.getItem('token');
        await fetch(process.env.REACT_APP_SERVER_URL + '/command/'+action, {
            method: "PATCH",
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({ client_id })
        })  
        .then((response) => response.json())
        .then((data) => {

            if (typeof data.error !== 'undefined' && data.error) {
                notify(data.reason, { type: 'error' });
            } else {
                notify("Blokada została zmieniona.", { type: 'success' });
                refresh();  
            }
        })
        .catch((error) => {
            notify(`Wystąpił błąd ${error}`, { type: 'error' });
            console.log(error)
        });
           return false;
    }
    return (
        <Button  key={client_id}
        variant={varriant} 
        onClick={handleButtonBlockClick} 
        label={label}>    
        </Button>
    )
}
