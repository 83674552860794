// in src/posts.js
import * as React from "react";
import {
  ListButton,
  Edit,
  TopToolbar,
  SimpleForm,
  TextInput,
  required,
  TextField,
  Labeled,
  useTranslate,
} from "react-admin";
import Stack from "@mui/material/Stack";
import { MuiColorInput } from "mui-color-input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useInput } from "react-admin";
import { Typography, Alert } from "@mui/material";
const ColorPicker = ({ source, label, ...props }) => {
  const { id, field, fieldState } = useInput({ source });
  return (
    <MuiColorInput
      id={id}
      {...field}
      variant="outlined"
      label={label}
      source={source}
      format="hex"
      {...props}
    />
    // <label htmlFor={id}>
    //     {label}
    //     <input id={id} {...field} />
    //     {fieldState.error && <span>{fieldState.error.message}</span>}
    // </label>
  );
};

const Logo = ({ source, label, ...props }) => {
  const { id, field, fieldState } = useInput({ source });
  return (
    <Stack
      direction="col"
      style={{ width: "100%", minHeight: "60px" }}
      gap={3}
      alignContent={"center"}
    >
      <div>
        <label htmlFor={id}>
          {label}
          <TextInput id={id} {...field} {...props} fullWidth />
          {fieldState.error && <span>{fieldState.error.message}</span>}
        </label>
      </div>
      <div>
        <img
          src={field.value}
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "5px",
          }}
        />
      </div>
    </Stack>
  );
};

const PostEditActions = () => (
  <TopToolbar>
    <Stack style={{ width: "100%" }} justifyContent="flex-start">
      <ListButton
        variant="contained"
        label="Wróć"
        size="medium"
        style={{ maxWidth: "120px" }}
      />
    </Stack>
  </TopToolbar>
);

export const AppEdit = () => {
  const [value, setValue] = React.useState("#ffffff");
  const translate = useTranslate();
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <Edit actions={<PostEditActions />}>
      <SimpleForm>
        <Labeled source="id">
          <TextField source="id" />
        </Labeled>

        <TextInput
          source="shop_name"
          variant="outlined"
          size="small"
          fullWidth
          validate={required()}
        />
        <TextInput
          source="shop_url"
          variant="outlined"
          size="small"
          fullWidth
        />
        <TextInput source="feed" variant="outlined" size="small" fullWidth />

        <Typography variant="h6" gutterBottom>
          {translate("resources.apps.headers.switcher_widget_options")}
        </Typography>
        <Stack
         direction={{ xs: 'column', sm: 'row' }}
         gap={5}    >
          <div>
            <TextInput
              source="redirect"
              label="Link do panelu Deluxeadmin"
              variant="outlined"
              size="small"
              fullWidth
            />

            <ColorPicker source="background" label="Kolor Tła" fullWidth/>
            <ColorPicker source="color" label="Kolor" fullWidth/>

            <Logo
              source="logo"
              label="Logo url"
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>

          <Alert variant="outlined" severity="info" style={{maxWidth:'300px'}}>
            <p>
              Aby aktywować widget na podstronach sklepu, należy w przypadku
              przeglądarki Google Chrome, udać się do panelu sklepu, następnie
              wcisnąć: f12, i w konsoli wpisać komendę aktywującą:
              <br />
              <b>localStorage.setItem('switcher-widget', 'active')</b>
            </p>
          </Alert>
        </Stack>
        <Labeled source="client_id">
          <TextField source="client_id" />
        </Labeled>
        <Labeled source="secret">
          <TextField source="secret" />
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
