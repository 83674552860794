import * as React from "react";
// import { Route } from 'react-router-dom';
import {
  Admin,
  Resource,
  localStorageStore,
  useStore,
  StoreContextProvider,
} from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import jsonServerProvider from "ra-data-json-server";
import { dataProvider } from "./misc/dataProvider.js";
import Dashboard from "./dashboard/Dashboard.js";
// import { useTranslate } from 'react-admin'
import polishMessages from "./i18n/pl";

import { NovaLayout } from "./layout/Layout";
import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import LogList from "./logs/LogList";

//themes
import { themes, ThemeName } from "./themes/themes";
//icons
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AdbOutlinedIcon from "@mui/icons-material/AdbOutlined";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
//lists
import { HistoryList } from "./history/HistoryList";
import { ProductList } from "./products/ProductList";


import { AppList } from "./apps/AppList";
import { AppCreate } from "./apps/AppCreate";
import { AppEdit } from "./apps/AppEdit";
import { DismissList } from "./dismiss/DismissList";

import { UserList } from "./users/UserList";
import { UserCreate } from "./users/UserCreate";
import { UserEdit } from "./users/UserEdit";
//
//
import { authProvider } from "./misc/authProvider";

const myTheme = merge({}, defaultTheme, {
  sidebar: {
    width: 300, // The default value is 240
    closedWidth: 55, // The default value is 55
  },
});

const i18nProvider = polyglotI18nProvider(
  (locale) =>
    locale === "en"
      ? import("./i18n/en").then((messages) => messages.default)
      : polishMessages,
  "pl", // Default ,
  [
    { locale: "pl", name: "Polski" },
    { locale: "en", name: "English" },
  ]
);

const store = localStorageStore(undefined, "MagShare");
  
const App = () => {
  const [themeName] = useStore("themeName", "default");
  const lightTheme = themes.find((theme) => theme.name === themeName)?.light;
  const darkTheme = themes.find((theme) => theme.name === themeName)?.dark;
  return (
    <Admin
      layout={NovaLayout}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      name="MagShare"
      disableTelemetry
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      defaultTheme="light"
      store={store}
    >
      <Resource
        name="getProducts"
        icon={Inventory2OutlinedIcon}
        list={ProductList}
        // show={ProductShow}
      />
      <Resource
        name="getHistory"
        icon={ListAltOutlinedIcon}
        list={HistoryList}
      />
      {/* <Resource
        name="getProductsOutOfStock"
        icon={DangerousIcon}
        list={ProductOutOfStockList}
      /> */}
      <Resource name="getDismiss" icon={WarningAmberIcon} list={DismissList} />
    
      <Resource
        name="apps"
        icon={AppRegistrationIcon}
        list={AppList}
        create={AppCreate}
        edit={AppEdit}
      />
       <Resource
        name="user"
        icon={PeopleAltIcon}
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
      />
        <Resource name="logs" icon={AdbOutlinedIcon} list={LogList} />
    </Admin>
  );
};

const AppWrapper = () => (
  <StoreContextProvider value={store}>
    <App />
  </StoreContextProvider>
);


export default AppWrapper;
