import getAuthTokensFromLocalStorage from "./getAuthTokensFromLocalStorage";

const refreshAuth = () => {
    const { accessToken } = getAuthTokensFromLocalStorage();

    if (accessToken.exp < (Math.round(+new Date()/1000)) && localStorage.getItem('refresh_pending')!=1) {
    
        localStorage.setItem('refresh_pending', 1);
        const token = localStorage.getItem('token');
        const refresh_token = localStorage.getItem('refresh_token');
        const user = localStorage.getItem('username');
        const request = new Request(process.env.REACT_APP_SERVER_URL + '/token-refresh', {
                method: 'POST',
                body: JSON.stringify({ token, refresh_token, user }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            })
        
        return fetch(request)
                            .then(response => {
                                if (response.status < 200 || response.status >= 300) {
                                    throw new Error(response.statusText);
                                }
                                return response.json();
                            })
                            .then(({ token, refresh_token }) => {
                                localStorage.setItem('token', token);
                                localStorage.setItem('refresh_token', refresh_token);
                                localStorage.setItem('refresh_pending', 0);
                                return Promise.resolve();
        }); 
    }
    return Promise.resolve();
}

export default refreshAuth 
