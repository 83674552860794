import {
    List, Datagrid, TextField, 
    Pagination, useListContext,
    useRecordContext
} from 'react-admin';
/**
 * Fetch a book from the API and display it
 */
 const MyTypeField = ({ source }) => {
    const record = useRecordContext();
    let color, val = record[source];

    if (val == "ALERT") {
        color = "red";
    } else {
        color = "black"
    }

    return (<span style={{ display: "block", textAlign: 'left' }}><TextField source="type" sx={{ color: color, "fontWeight": "bold", "textAlign": "right" }} /></span>);
};
const PostPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;
const LogList = () => {
    const { data, isLoading } = useListContext();
    if (isLoading) return (<div>Loading</div>);
    return (
        <List  sort={{ field: 'created_at', order: 'DESC' }} pagination={<PostPagination />}>
        <Datagrid>
            <TextField source="date" />
            <TextField source="ip" />
            <MyTypeField source="type" />
            <TextField source="log" />
        </Datagrid> 
    </List>
    )
};





export default LogList;