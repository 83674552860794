// in tags/TagCreate.js
import { React, useEffect, useState } from "react";
import {
  Show,
  SaveButton,
  Toolbar,
  TextField,
  Labeled,
  useRecordContext,
} from "react-admin";
import { Stack } from "@mui/material";
import AvailableField from "../components/AvailableField";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDataProvider } from "react-admin";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

// const data = [
//   {
//     created_at: "2024-02-01",
//     value: "1",
//   },
//   {
//     created_at: "2024-02-02",
//     value: "9",
//   },
//   {
//     created_at: "2024-02-03",
//     value: "16",
//   },
//   {
//     created_at: "2024-02-04",
//     value: "32",
//   },
//   {
//     created_at: "2024-02-05",
//     value: "51",
//   },
//   {
//     created_at: "2024-02-06",
//     value: "51",
//   },
//   {
//     created_at: "2024-02-07",
//     value: "56",
//   },
//   {
//     created_at: "2024-02-08",
//     value: "56",
//   },
//   {
//     created_at: "2024-02-09",
//     value: "46",
//   },
//   {
//     created_at: "2024-02-10",
//     value: "28",
//   },
//   {
//     created_at: "2024-02-11",
//     value: "34",
//   },
//   {
//     created_at: "2024-02-12",
//     value: "47",
//   },
//   {
//     created_at: "2024-02-13",
//     value: "72",
//   },
//   {
//     created_at: "2024-02-14",
//     value: "33",
//   },
// ];
const ProductShow = (props) => {
  const [data, setData] = useState([]);
  const { id } = props;

  const dataProvider = useDataProvider();
  const outArray = [];
  useEffect(() => {
    dataProvider
      .getOne("charts/product-sale-30d", { id: id })
      .then(({ data }) => {
        for (let i = 0; i < data.chartData.length; i++) {
          outArray.push({
            name: data.chartData[i].date,
            value: parseInt(data.chartData[i].value),
          });
        }
        setData(outArray);
        // setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // setLoading(false);
      });
  }, []);
  return (
    <>
      {/* <DialogTitle>
     </DialogTitle> */}
      <Show {...props} component="div">
        <Stack justifyContent="space-between" direction="column" spacing={2}>
          <Labeled source="product_name" label="Produkt: ">
            <TextField source="product_name" />
          </Labeled>
          <Stack
            direction="row"
            spacing={6}
            justifyContent="space-between"
            sx={{ width: "30%" }}
          >
          <Labeled source="id" label="ID: ">
            <TextField source="id" />
          </Labeled>
          <Labeled source="product_ean" label="EAN: ">
            <TextField source="product_ean" />
          </Labeled>
          </Stack>
          <Stack
            direction="row"
            spacing={6}
            justifyContent="space-between"
            sx={{ width: "60%" }}
          >
            <Labeled
              source="quantity_available"
              label="Dostępne:"
              sx={{ width: "100%" }}
            >
              <TextField source="quantity_available" />
            </Labeled>

            <Labeled
              source="quantity_reserved"
              label="Zarezerwowane:"
              sx={{ width: "100%" }}
            >
              <TextField source="quantity_reserved" />
            </Labeled>
            <Labeled
              source="quantity_on_hand"
              label="w magazynie: "
              sx={{ width: "100%" }}
            >
              <TextField source="quantity_on_hand" />
            </Labeled>
            <Labeled
              source="block_change"
              label="Blokada stanów:"
              sx={{ width: "100%" }}
            >
              <AvailableField source="block_change" />
            </Labeled>
          </Stack>
          <Labeled label="Sprzedaż ostatnich 30 dni:" sx={{ width: "100%" }}>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart
                height={200}
                data={data}
                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
              >
                <XAxis dataKey="name" />
                <YAxis dataKey="value" label="szt." name="szt." />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Tooltip />
                {/* <Line type="monotone" dataKey="uv" stroke="#8884d8" /> */}
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Labeled>
        </Stack>
      </Show>
    </>
  );
};

export default ProductShow;
