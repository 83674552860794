import * as React from "react";
import { Create, SimpleForm, TextInput, required } from 'react-admin';
 
export const UserCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="username" validate={[required()]} fullWidth />
            <TextInput source="name" fullWidth />
            <TextInput source="surname"  fullWidth />
            <TextInput source="email" label="Email" fullWidth/>
            <TextInput source="password" label="Hasło" validate={[required()]} fullWidth />

        </SimpleForm>
    </Create>
);
