import { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";
import { Card, CardContent } from "@mui/material";
import {
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const InfoPanel = (props) => {
  const {
    header,
    difference,
    positive = false,
    disableColor = false,
    sx,
    value,
    resource,
    action,
    sign = "",
  } = props;
  const dataProvider = useDataProvider();
  const valueColor = disableColor ? '' : positive ? "success.main" : "error.main";
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getOne(resource, { id: action })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);
  return (
      <Card sx={sx}>
        <CardContent>
          <Stack
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="overline">
                {header}
              </Typography>
              {!loading ? (
                <Typography
                  variant="h4"
                  color={valueColor}
                >
                  {data.count<0 && sign}
                  {data.count}
                </Typography>
              ) : (
                <CircularProgress size={25} />
              )}
            </Stack>
            {/* <Avatar
            sx={{
              backgroundColor: 'error.main',
              height: 56,
              width: 56
            }}
          >
            <SvgIcon>
              <div class="dolar" />
            </SvgIcon>
          </Avatar> */}
          </Stack>
          {difference && (
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Stack alignItems="center" direction="row" spacing={0.5}>
                <SvgIcon
                  color={positive ? "success" : "error"}
                  fontSize="small"
                >
                  {positive ? <p>UP</p> : <p>DOWN</p>}
                </SvgIcon>
                <Typography
                  color={positive ? "success.main" : "error.main"}
                  variant="body2"
                >
                  {difference}%
                </Typography>
              </Stack>
              <Typography color="text.secondary" variant="caption">
                Since last month
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>

  );
};

export default InfoPanel;
