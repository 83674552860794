import * as React from "react";
import { Card, CardContent } from "@mui/material";

import BarChartIcon from "@mui/icons-material/BarChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from "react-admin";
import { quantityProvider, quantityRanges } from "../misc/quantityRanges";

const Aside = () => {
  return (
    <Card
      sx={{
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        marginTop: "65px",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        {/* <FilterLiveSearch hiddenLabel /> */}

        {/* <SavedQueriesList /> */}

        <FilterList
          label="resources.getProducts.filters.stock"
          icon={<BarChartIcon />}
        >
          {quantityRanges &&
            quantityRanges.map((range) => (
              <FilterListItem
                label={range.translate}
                value={{
                  qa_lt: range.from,
                  qa_gt: range.to,
                  qa: range.qa,
                }}
              />
            ))}
        </FilterList>
        <FilterList
          label="resources.getProducts.filters.block_stock"
          icon={<BarChartIcon />}
        >
          <FilterListItem
            label="resources.getProducts.filters.yes"
            value={{
                block_change: 1
            }}
          />
           <FilterListItem
            label="resources.getProducts.filters.no"
            value={{
                block_change: 0
            }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
