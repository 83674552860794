import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  Pagination,
  TextInput,
  SelectInput,
  useRecordContext,

} from "react-admin";
import { OperationTags } from "./OperationTags";
import ButtonBlock from "../components/ButtonBlock";
import Box from "@mui/material/Box";
import LockIcon from '@mui/icons-material/Lock';
// const symbologies = [
//   STANDARD_SYMBOLOGY_KEYS['EAN-8'],
//   STANDARD_SYMBOLOGY_KEYS['EAN-13'],
// ]


const NumberFieldWithLockIcon = ({ source }) => {
  const record = useRecordContext();
  const blocked = parseInt(record['blocked']);

  return (
    <Box display="flex" alignItems="center">
      
      <NumberField source={source} />
      {blocked==1 && <LockIcon fontSize="10px"/>}
    </Box>
  );
}
const LockIconField = ({ source }) => {
  const record = useRecordContext();
  const blocked = parseInt(record['blocked']);

  return (
    <Box name="Blokada stanów" display="flex" alignItems="center">
    
      {blocked==1 && <LockIcon/>}
    </Box>
  );
}
const PostPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;
const postFilters = [
  <TextInput
    source="product_id"
    label="ID produktu"
    variant="outlined"
    alwaysOn
  />,
  <SelectInput
    label="Operacja"
    source="type"
    variant="outlined"
    choices={[
      { id: "INVENTORY", name: "Inwentaryzacja" },
      { id: "RESERVATION", name: "Rezerwacja" },
      { id: "CANCEL_RESERVATION", name: "Wycofanie rezerwacji" },
      { id: "CORRECTION", name: "Korekta" },
      { id: "PRODUCT_CREATE", name: "Utworzenie produktu" },
    ]}
    alwaysOn
  />,
  <SelectInput
    label="Źródło"
    source="source"
    variant="outlined"
    choices={[
      { id: "TREV", name: "TREV" },
      // { id: 'www.aptekarosa.pl', name: 'www.aptekarosa.pl' },
      // { id: 'www.aleleki.pl', name: 'www.aleleki.pl' },
      // { id: 'www.drogeriarosa.pl', name: 'www.drogeriarosa.pl' },
    ]}
    alwaysOn
  />,
  <TextInput source="ean" label="ean" variant="outlined" />,
];
export const DatagridUsersProps = {
  rowStyle: (record, index) => {
    return {
      backgroundColor: record.qa < 0 && "silver",
      color: record.qa < 0 && "#616161",
    };
  },
};

const ProductNameTextField = ({ source }) => {
  const record = useRecordContext();
  let color,
    val = parseInt(record[source]);

  if (val < 0) {
    color = "red";
  } else if (val == 0) {
    color = "orange";
  } else {
    color = "black";
  }
  return (
    <span>
      <TextField source={source} />
    </span>
  );
  //return (<span style={{ color:color }}><TextField source={source} sx={{ color: color }} /></span>);
};
export const HistoryList = (props) => {
 

  return (
    <div>
      <List
        size="small"
        sort={{ field: "created_at", order: "DESC" }}
        filters={postFilters}
        pagination={<PostPagination />}
        perPage={25}
      >
        <Datagrid {...DatagridUsersProps} bulkActionButtons={false}>
          <TextField source="product_id" />
          <ProductNameTextField source="product_name" />
          <OperationTags source="operation" />
          <TextField source="reason" />
          <NumberField source="value" />
          <NumberFieldWithLockIcon source="quantity_before" />
          <NumberField sx={{ fontWeight: "bold" }} source="quantity_after" />
          <LockIconField/>
          <TextField source="source" />
          <DateField source="created_at" showTime />
        </Datagrid>
      </List>
    </div>
  );
};
