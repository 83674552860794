// in tags/TagCreate.js
import React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  TextField,
  Labeled,
} from "react-admin";
import { Box, Grid, Stack, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";

const ProductEditQuantityToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Aktualizuj stan" />
  </Toolbar>
);
const ProductModify = ({ onCancel, ...props }) => (
  <Edit {...props}>
    <Stack
      direction="row"
      sx={{
        marginTop: "40px",
        marginLeft: "10px",
        marginRight: "10px",
        minWidth: "350px",
      }}
    >
      <Typography variant="h6" flex="1">
        <Labeled source="product_name" label="Produkt: ">
          <TextField source="product_name" />
        </Labeled>
      </Typography>
      <IconButton onClick={onCancel} size="small">
        <CloseIcon />
      </IconButton>
    </Stack>
    <SimpleForm toolbar={<ProductEditQuantityToolbar />}>
      <TextInput source="quantity_available" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default ProductModify;
