import { useDataProvider } from "react-admin";
import React, { useEffect, useState } from "react";
import { CardHeader } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Bar
} from "recharts";

const BasketAvgChart = (props) => {
  const { resource, action } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();

  const outArray = [];
  useEffect(() => {
    dataProvider
      .getOne(resource, { id: action })
      .then(({ data }) => {
        for (let i = 0; i < data.chartData.length; i++) {
          outArray.push({
            date: data.chartData[i].date,
            avg_sku: data.chartData[i].avg_sku,
            avg_quantity: data.chartData[i].avg_quantity,
          });
        }
        setData(outArray);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <CardHeader title="Rozmiar koszyka" />
      {data && (
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            height={300}
            data={data}
            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
          >
            <XAxis dataKey="date" allowDecimals />
            <YAxis dataKey="avg_quantity" allowDecimals/>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Tooltip />
            <Bar dataKey="avg_quantity" barSize={20} fill="#413ea0" />
            <Line
              name="Śr. ilość produktów w koszyku"
              strokeWidth={3}
              type="monotone"
              dataKey="avg_quantity"
              stroke="#8884d8"
            />
            <Line
              name="Śr. liczba SKU w koszyku"
              strokeWidth={3}
              type="monotone"
              dataKey="avg_sku"
              stroke="#339900"
            />
            <Legend verticalAlign="bottom" height={36}/>
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default BasketAvgChart;
