import * as React from "react";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Box,
} from "@mui/material";
import { stringify } from 'query-string';
import { Button, Link} from "react-admin";
export const BestSellerItem = ({ item }) => {
  const img =
    item.img != undefined && item.img != "" ? item.img : "/product_img.webp";
  return (
    <ListItem
      button
      // component={Link}
      // to={`/products/1`}
    >
      <ListItemAvatar>
        {img && (
          <Avatar
            src={`${img}`}
            sx={{ bgcolor: "background.paper" }}
            variant="rounded"
          />
        )}
      </ListItemAvatar>

      <Button
            size="small"
            color="primary"
            component={Link}
            to={{
                pathname: '/getProducts',
                search: stringify({
                    filter: JSON.stringify({ q: item.id }),
                }),
            }}
            state={{ _scrollToTop: true }}
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
            }}
        >
            {item.name}
            </Button>

      <ListItemSecondaryAction>
        <Box
          component="div"
          sx={{
            // marginRight: '1em',
            color: "text.primary",
            textAlign: "right",
          }}
        >
          {parseInt(item.quantity)} szt.
        </Box>
        <Box
          component="div"
          sx={{
            // marginRight: '1em',
            color: "#555",
            fontSize: "11px",
            textAlign: "right",
          }}
        >
          (dostępne: {item.qa})
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
