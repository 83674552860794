import * as React from "react";
import { Create, SimpleForm, TextInput, required } from 'react-admin';
 
export const AppCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="shop_name" validate={[required()]} fullWidth />
            <TextInput source="shop_url" label="Short description" />
           
        </SimpleForm>
    </Create>
);
