
import { useDataProvider } from "react-admin";
import React, { useEffect, useState } from "react";
import { CardHeader } from "@mui/material";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
  } from "recharts";

const OrdersChart = (props) => {
    const { resource, action } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
  
    const outArray = [];
    useEffect(() => {
      dataProvider
        .getOne(resource, { id: action })
        .then(({ data }) => {
          for (let i = 0; i < data.data.length; i++) {
            outArray.push({
              name: data.data[i].created_at,
              value: parseInt(data.data[i].value),
            });
          }
          setData(outArray);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }, []);
   
  
    return (
      <>
                <CardHeader title="Zamówienia - ostatnie 30 dni" />
        {data && (
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              height={300}
              data={data}
              margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis dataKey="name" />
              <YAxis dataKey="value" />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
              <Tooltip />
              {/* <Line type="monotone" dataKey="uv" stroke="#8884d8" /> */}
              <Line
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </>
    );
  };

  export default OrdersChart;