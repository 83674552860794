import { Grid, CardContent, Card } from "@mui/material";
import InfoPanel from "../components/InfoPanel";
import OrdersChart from "./OrdersChart";
import BasketAvgChart from "./BasketAvgChart"; 
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { ListBestSeller }  from "./ListBestSeller";
const Dashboard = (props) => {
  
  return (
    <>
      {/* <Container maxWidth="xl"> */}

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={3}>
          <InfoPanel
            header="Produktów w bazie"
            resource="dashboard"
            action="count-products"
            // difference={12}
            positive
            // sx={{ height: "100%" }}
          ></InfoPanel>
        </Grid>
        <Grid item  xs={12} sm={3}>
          <InfoPanel
            header="SKU rezerwacje (dziś)"
            resource="dashboard"
            action="count-daily-stock-reservation"
            sign="-"
            //difference={12}
            //positive = {false}
            sx={{ height: "100%" }}
          ></InfoPanel>
        </Grid>
        <Grid item  xs={12} sm={3}>
          <InfoPanel
            header="Zamówienia (ten miesiąc)"
            resource="dashboard"
            action="count-orders-this-month"
            disableColor
            //  difference={12}
            positive
            sx={{ height: "100%" }}
          ></InfoPanel>
        </Grid>
        <Grid item  xs={12} sm={3}>
          <InfoPanel
            header="Zamówienia (ostatni miesiąc)"
            resource="dashboard"
            action="count-orders-last-month"
            disableColor
            // difference={12}
            positive
            sx={{ height: "100%" }}
          ></InfoPanel>
        </Grid>
        {/* <Grid item xs="6">empty</Grid> */}
        <Grid item xs={12} sm={6}>
          {" "}
          <Card>
            <CardContent>
  
              <OrdersChart
                resource="dashboard"
                action="chart-last-30-orders"
              ></OrdersChart>
            </CardContent>
          </Card>
        </Grid>
       
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
  
              <BasketAvgChart
                resource="charts"
                action="basket_avarage_size"
              ></BasketAvgChart>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ListBestSeller resource="dashboard" action="best-sellers-7d-7"></ListBestSeller>
        </Grid>
        <Grid item xs={12} sm={6}>
         
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
