
// import { Route } from 'react-router-dom';
import { addRefreshAuthToDataProvider, fetchUtils } from 'react-admin'
import jsonServerProvider from 'ra-data-json-server'
import refreshAuth from './refreshAuth.js'

const httpClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    if (token != undefined && token != "") {
        options.headers.set('Authorization', `Bearer ${token}`);
    }
    const { status, headers, body, json } = await fetchUtils.fetchJson(url, options);
    //console.log('fetchJson result', { status, headers, body, json });
    return { status, headers, body, json };
}


//const dataProvider = jsonServerProvider(process.env.REACT_APP_SERVER_URL, httpClient);
const baseDataProvider = jsonServerProvider(process.env.REACT_APP_SERVER_URL , httpClient);
export const dataProvider = addRefreshAuthToDataProvider(baseDataProvider, refreshAuth);

