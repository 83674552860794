import React from "react";
import { useCallback } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TopToolbar,
  FilterButton,
  ExportButton,
  DateField,
  NumberField,
  useListContext,
  useRecordContext,
  useSafeSetState,
  useNotify,
  useRefresh,
  Pagination,
  TextInput,
  SelectInput,
  Button,
  BulkDeleteButton,
  BulkExportButton,
  ListBase,
  NumberInput,
  SearchInput,
  ReferenceInput,
} from "react-admin";

import {
  ListItemAvatar,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import LinkToHistory from "./LinkToHistory";
import ProductModify from "./ProductModify";
import ProductShow from "./ProductShow";
import ButtonBlock from "../components/ButtonBlock";
import Drawer from "@mui/material/Drawer";
import LockIcon from "@mui/icons-material/Lock";
import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Aside from "./Aside";

import { quantityProvider } from "../misc/quantityRanges"

const PostPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;

const QFieldQA = ({ source }) => {
  const record = useRecordContext();
  let color,
    val = parseInt(record[source]),
    block = parseInt(record["block_change"]);

  color = quantityProvider.getQuantityColor(val);

  return (
    <>
      <span style={{ display: "block", textAlign: "right" }}>
        <NumberField
          textAlign="right"
          source="quantity_available"
          sx={{ color: color, fontWeight: "bold", textAlign: "right" }}
        />
        {block == 1 && <LockIcon fontSize="10px" />}
      </span>
    </>
  );
};

const PostBulkActionButtons = (...props) => {
  const { selectedIds, onUnselectItems } = useListContext(props);
  const [isOpen, setOpen] = useSafeSetState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const token = localStorage.getItem("token");
  const handleButtonBlockClick = async () => {
    await fetch(
      process.env.REACT_APP_SERVER_URL + "/command/block_quantities",
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: selectedIds }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        refresh();
        notify(
          `${data.affected} elementów zostało oznaczone do blokowania stanów`,
          {
            type: "info",
            messageArgs: { smart_count: selectedIds.length },
          }
        );
        onUnselectItems();
        setOpen(false);
      });
  };
  const handleButtonUnBlockClick = async () => {
    await fetch(
      process.env.REACT_APP_SERVER_URL + "/command/unblock_quantities",
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: selectedIds }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        refresh();
        notify(
          `Dla ${data.affected} elementów zostało usunięte blokowanie stanów`,
          {
            type: "info",
            messageArgs: { smart_count: selectedIds.length },
          }
        );
        onUnselectItems();
        setOpen(false);
      });
  };

  return (
    <>
      <Button label="Usuń blokadę stanów" onClick={handleButtonUnBlockClick} />
      <Button label="Blokuj stany" onClick={handleButtonBlockClick} />
      <BulkExportButton />
      <BulkDeleteButton />
    </>
  );
};

const QField = (props) => {
  const record = useRecordContext();

  return (
    <span style={{ color: "#777", textAlign: "right", display: "block" }}>
      {record && record[props.source]}
    </span>
  );
};

const Filters = [
  <TextInput
    source="q"
    variant="outlined"
    label="Szukaj nazwa lub id"
    alwaysOn
  />,
  <TextInput source="product_ean" variant="outlined" label="EAN" alwaysOn />,
  <SelectInput
    label="Blokada stanu"
    source="block_change"
    variant="outlined"
    choices={[
      { id: 0, name: "Nie" },
      { id: 1, name: "Tak" },
    ]}
    alwaysOn
  />,
];

const ProductActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
      {/* <CreateButton sx={{borderRadius:"0px"}}
                variant="contained"
                label="Dodaj produkt"
            /> */}
    </TopToolbar>
  );
};
const PostListActionToolbar = ({ children, ...props }) => (
  <Box sx={{ alignItems: "center", display: "flex" }}>{children}</Box>
);

const MenuButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <div>
      <LinkToHistory />
    </div>
  );
};



const ProductThumbnail = ({ source }) => {
  const record = useRecordContext();
  const img = record != undefined && record[source] != undefined ? record[source] : "/product_img.webp";
  return (
    <ListItemAvatar>
      {img && (
        <Avatar
          src={img}
          sx={{ bgcolor: "background.paper" }}
          variant="rounded"
        />
      )}
    </ListItemAvatar>
  );
}
export const ProductList = (props) => {
  const { data, isLoading } = useListContext();
  const location = useLocation();
  const navigate = useNavigate();
  const match = matchPath("/getProducts/:id", location.pathname);
  const matchShow = matchPath("/getProducts/:id/show", location.pathname);
  const handleClose = useCallback(() => {
    navigate("/getProducts", { replace: true });
  }, [navigate]);


  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  //console.log(match.params.id);
  if (isLoading) return <div>Loading</div>;
  return (
    <>
      <ListBase perPage={24} sort={{ field: "reference", order: "ASC" }}>
        <Box display="flex">
          <Aside />
          <List 
          sx={{ width: "100%", marginRight:'10px' }}
            // debounce={10}
            size="small"
            sort={{ field: "product_name", order: "ASC" }}
            filters={Filters}
            actions={<ProductActions />}
            perPage={25}
            pagination={<PostPagination />}
          >
            <Datagrid
              size="small"
              rowClick="show"
              bulkActionButtons={<PostBulkActionButtons />}
              stickyHeader
            >
     
              <ProductThumbnail source="product_thumb" />
              <TextField source="product_id" />
              <TextField source="product_name" />
              <TextField source="product_ean" />
            
              {/* <QField source="quantity_reserved" /> */}
              <QFieldQA source="quantity_available" />
              <Box source="block_change">
                <ButtonBlock source="id" />
              </Box>
              <DateField source="quantity_change_date" showTime />

              <PostListActionToolbar>
                <EditButton label="Zmień stan" />
                <MenuButton />
              </PostListActionToolbar>
            </Datagrid>
          </List>
        </Box>
        <Drawer
          variant="persistent"
          open={!!match}
          anchor="right"
          onClose={handleClose}
          sx={{ zIndex: 100 }}
        >
          {!!match && (
            <ProductModify id={match.params.id} onCancel={handleClose} />
          )}
        </Drawer>

        {
          <Dialog
            fullWidth
            maxWidth="xl"
            open={matchShow}
            onClose={handleClose}
          >
            <DialogContent>
              {!!matchShow && <ProductShow id={matchShow.params.id} />}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus label="Zamknij" variant="contained" /> 
          
            </DialogActions>
          </Dialog>
        }
      </ListBase>
    </>
  );
};



export const productFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    source="category_id"
    reference="categories"
    sort={{ field: "id", order: "ASC" }}
  >
    <SelectInput source="name" />
  </ReferenceInput>,
  <NumberInput source="width_gte" />,
  <NumberInput source="width_lte" />,
  <NumberInput source="height_gte" />,
  <NumberInput source="height_lte" />,
];
