import * as React from 'react';
import { Card, CardHeader, List } from '@mui/material';
import { useTranslate, useDataProvider } from 'react-admin';
import { BestSellerItem } from './BestSellerItem';
import { useEffect, useState } from "react";

export const ListBestSeller = ({ resource, action }) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        dataProvider
          .getOne(resource, { id: action })
          .then(({ data }) => {
            setData(data.data);
            setLoading(false);
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      }, []);
    return (
        <Card sx={{ flex: 1 }}>
            {/* <CardHeader title={translate('pos.dashboard.pending_orders')} /> */}
            <CardHeader title="Najczęściej sprzedające się produkty (ostatnie 7dni)" />
            <List dense={true}> 
                {data && data.map(item => (
                  
                    <BestSellerItem key={item.id} item={item} />
                ))}
            </List>
        </Card>
    );
};

